import React from 'react';
import Text from 'components/typography/text/Text';

const Info = ({ children }) => {
  return (
    <Text tag="span" className="info-popup">
      {children}
    </Text>
  );
};

export default Info;
